<template>
  <div>
    <h4 class="font-weight-bold pb-1 primary--text">Gestión</h4>
    <h5 class="font-weight-light pb-3">Gestión de Presupuestos</h5>

    <v-card class="elevation-15" id="cardborde">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="verde"
      ></v-progress-linear>
      <v-card-title class="font-weight-light">
        Lista de Presupuestos
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          color="primary"
          label="Buscar"
          hide-details
          class="m-2"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          class="mb-2 verde primary--text"
          @click="$router.push({ name: 'presupuesto' })"
          >Nuevo Presupuesto</v-btn
        >
      </v-card-title>
      <v-tabs right v-if="usuarioDB.data.role == 'ADMIN'">
        <v-tab @click="bEstado = 'Proyectos'" v-if="verHitos">Proyectos</v-tab>
        <v-tab @click="bEstado = 'ITO'" v-if="verHitos">ITO</v-tab>
        <v-tab @click="bEstado = 'Todos'" v-if="verHitos">Todos</v-tab>
      </v-tabs>
      <v-tabs
        right
        v-else-if="usuarioDB.data.role != 'ADMIN' && usuarioDB.data.area == 'PROYECTOS'"
      >
        <v-tab @click="bEstado = 'Proyectos'">Proyectos</v-tab>
      </v-tabs>
      <v-tabs
        right
        v-else-if="usuarioDB.data.role != 'ADMIN' && usuarioDB.data.area == 'ITO'"
         v-if="verHitos"
      >
        <v-tab @click="bEstado = 'ITO'">ITO</v-tab>
      </v-tabs>
      <v-data-table
        class="mt-3"
        :headers="headers"
        :items="filteredItems"
        :no-results-text="noresult"
        no-data-text="Sin resultados que mostrar"
        :search="search"
        :custom-filter="filterOnlyCapsText"
        :sort-by="['nro_presupuesto']"
        :sort-desc="[true]"
        :custom-sort="customSort"
        :footer-props="{
          'items-per-page-text': 'Resultados por página',
          'items-per-page-all-text': 'Todos',
        }"
      >
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:item.url="{ item }">
          <v-icon small class="mr-2" @click="verURL(item.url)"> mdi-web </v-icon>
        </template>
        <template v-slot:item.archivo_presupuesto="{ item }">
          <v-icon
            class="mr-2"
            @click="abrirArchivo(item.archivo_presupuesto)"
            v-if="item.archivo_presupuesto && item.archivo_presupuesto != 'undefined'"
          >
            mdi-file-download-outline
          </v-icon>
        </template>
        <template v-slot:item.accion="{ item }">


          <v-chip
            class="mr-2 p-1"
            color="primary"
            text-color="verde"
            small
          >
            <v-icon
            small
            @click="editItem(item._id)"
            >
            mdi-pencil
            </v-icon>
          </v-chip>

          
          <v-chip
            class="mr-2 p-1"
            color="primary"
            text-color="red"
            small
            v-if="usuarioDB.data.role == 'ADMIN'"
          >
            <v-icon
            small
            @click="deleteItem(item._id)"
          >
            mdi-delete
            </v-icon>
          </v-chip>
        </template>

        <template v-slot:item.mandante="{ item }">

          <div v-if="item.obras">
            
            <div v-if="item.mandante">
              <v-chip class="ma-0 p-1" dark x-small label  color="green"><b>M</b></v-chip> {{ item.mandante.nombre }}
            </div>
            <div v-else>
              <v-chip class="ma-0 p-1" dark x-small label  color="green"><b>M</b></v-chip> Sin mandante
              
            </div>
            <v-chip  class="ma-0 p-1" dark x-small label  color="brown"><b>O</b></v-chip>
            {{ item.obra.nombre }}
            
          </div>
          <div v-else>
            <!----<v-chip class="ma-0 p-1" dark x-small label  color="green"><b>M</b></v-chip>--> 
            <div v-if="item.mandante">
            {{ item.mandante.nombre }}
            </div>
            <div v-else>
              Sin mandante

            </div>
          </div>

          
        </template>

        <template v-slot:item.status="{ item }">
          <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
          <v-chip class="ma-0" dark small label outlined :color="getEstados(item.status)">
            <b>{{ item.status }}</b>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="snackbar" color="green" dark timeout="1500" align="center">
      URL copiada exitosamente!
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

//Definimos la clase de los elementos

export default {
  data() {
    return {
      loading: true,
      noresult: "No existen resultados",
      search: "",
      dialogVerURL: false,
      urlSitio: "",
      snackbar: false,
      verHitos: false,
      headers: [
        {
          text: "Nº Ppto",
          align: "start",
          sortable: true,
          value: "nro_presupuesto",
          width: "15%",
        },
        /*{
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "tipo",
        },*/
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombreProyecto",
          width: "20%",
        },
        {
          text: "Mandante",
          value: "mandante",
          width: "20%",
        },

        {
          text: "Valor",
          value: "valor",
          width: "10%",
        },
        {
          text: "F. Envío",
          value: "fecha_envio",
        },
        {
          text: "Estado",
          value: "status",
        },
        {
          text: "Doc",
          value: "archivo_presupuesto",
        },

        {
          text: "Accion",
          value: "accion",
          sortable: "false",
          width: "10%",
        },
      ],
      desserts: [],
      bEstado: "Proyectos",
      tipo: [
        {
          tipo: "1",
          text: "Proyectos",
        },
        {
          tipo: "2",
          text: "ITO",
        },
      ],
      tipos_moneda: [
        {
          tipo: 1,
          text: "U.F",
        },
        {
          tipo: 2,
          text: "Pesos Chilenos",
        },
      ],
      estados: [
        {
          tipo: 1,
          text: "Pendiente",
        },
        {
          tipo: 2,
          text: "Aprobado",
        },
        {
          tipo: 3,
          text: "Rechazado",
        },
      ],
    };
  },

  computed: {
    ...mapState(["token", "notificationSystem", "usuarioDB"]),
    filteredItems() {
      if (this.bEstado == "Todos") {
        return this.desserts;
      } else if (this.bEstado == "ITO") {
        return this.desserts.filter((i) => {
          return i.tipo === this.bEstado || i.valorIto > 0;
        });
      } else {
        return this.desserts.filter((i) => {
          return i.tipo === this.bEstado || i.valorProyectos > 0;
        });
      }

      //return this.desserts;
    },
  },
  methods: {
    abrirArchivo(archivo) {
      window.open(
        process.env.VUE_APP_BASE_URL + "/presupuestos/bajarDocumentoEstandar/" + archivo,
        "_blank"
      );
    },
    editItem(id) {
      this.$router.push({
        name: "presupuesto",
        params: {
          id: id,
        },
      });
    },
    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      let filtro = this.$options.filters;

      axios.get("/presupuestos/presupuesto/", config).then((response) => {
        //this.desserts = response.data;
        var dataResponse = response.data;
        for (let dato of dataResponse) {
          dato.status = filtro.filtro_status(dato.status, this.estados);
          dato.fecha_envio = filtro.fecha_normal(dato.fecha_envio);
          dato.tipo = filtro.filtro_tipos(dato.tipo, this.tipo);
          dato.valor = filtro.filtro_valor(
            filtro.formato_numero(dato.valor),
            dato.tipo_moneda
          );
        }
        this.desserts = dataResponse;
        console.log(this.desserts);
        this.loading = false;
      });
    },
    async deleteItem(item) {
      this.loading = true;
      let config = {
        headers: {
          token: this.token,
        },
      };

      await axios
        .delete("/presupuestos/presupuesto/" + item, config)
        .then((response) => {
          this.getData();
          this.$toast.success(
            "Eliminado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    getEstados(estado) {
      if (estado == "Pendiente") return "orange";
      else if (estado == "Aprobado") return "light-green darken-2";
      else if (estado == "Rechazado") return "red darken-4";
    },

    getEstadosTexto(estado) {
      if (estado == true) return "check";
      else if (estado == false) return "close";
      else return "N/A";
    },

    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    customSort(items, index, isDesc) {
      let comparador = (fecha1, fecha2, desc) => {
        if (!fecha1) {
          return 1;
        }
        if (!fecha2) {
          return -1;
        }

        let fecha1Splitted = fecha1.split("/");
        let fecha2Splitted = fecha2.split("/");
        let fecha11 =
          fecha1Splitted[2] + "-" + fecha1Splitted[1] + "-" + fecha1Splitted[0];
        let fecha22 =
          fecha2Splitted[2] + "-" + fecha2Splitted[1] + "-" + fecha2Splitted[0];
        let date1 = new Date(fecha11);
        let date2 = new Date(fecha22);
        return date1 < date2 ? 1 : -1;
      };

      let nros_proyectos = (a, b, isDesc) => {
        let va = a.replace(/[^0-9]+/g, "");
        let vb = b.replace(/[^0-9]+/g, "");
        if (!isDesc) {
          if (va < vb) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (vb < va) {
            return 1;
          } else {
            return -1;
          }
        }
      };

      items.sort((a, b) => {
        if (index[0] === "fecha_estimada_realizacion") {
          if (!isDesc[0]) {
            return comparador(
              a.fecha_estimada_realizacion,
              b.fecha_estimada_realizacion,
              2
            );
          } else {
            return comparador(
              b.fecha_estimada_realizacion,
              a.fecha_estimada_realizacion,
              1
            );
          }
        } else if (index[0] === "nro_proyecto") {
          return nros_proyectos(a.nro_proyecto, b.nro_proyecto, isDesc[0]);
        } else if (index[0] === "nro_presupuesto") {
          return nros_proyectos(a.nro_presupuesto, b.nro_presupuesto, isDesc[0]);
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
  },
  created() {
    this.getData();

    if (this.usuarioDB.data.role != "ADMIN" && this.usuarioDB.data.area == "PROYECTOS") {
      this.bEstado = "PROYECTOS";
    } else if (this.usuarioDB.data.role != "ADMIN" && this.usuarioDB.data.area == "ITO") {
      this.bEstado = "ITO";
    }
  },
  filters: {
    filtro_tipos: function (valor, tipos) {
      if (tipos.filter((res) => res.tipo == valor).length > 0) {
        return tipos.filter((res) => res.tipo == valor)[0].text;
      } else {
        return "";
      }
    },
    filtro_status: function (valor, tipos) {
      if (tipos.filter((res) => res.tipo == valor).length > 0) {
        return tipos.filter((res) => res.tipo == valor)[0].text;
      } else {
        return "";
      }
    },
    filtro_valor: function (valor, tipo_moneda) {
      if (tipo_moneda == "1") {
        return `U.F ${valor}`;
      } else {
        return `$ ${valor}`;
      }
    },
    fecha_normal: function (valor) {
      return moment(valor, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    formato_numero(valor) {
      return new Intl.NumberFormat("de-DE").format(Math.round(valor).toFixed(0));
    },
  },
};
</script>
<style>
th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
</style>
